import React, { useState } from 'react'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import CTA from '../components/common/CTA'
import FeaturedPosts from '../components/resources/FeaturedPosts'
import AllPosts from '../components/resources/AllPosts'
import Help from '../components/help/Help'

const HelpPage = ({ data }) => {
  const guides = data.guides.nodes
    .filter(node => node.frontmatter.template === 'SingleArticle')
    .flatMap(node => node.frontmatter)
    .slice(0, 6)

  const docs = data.docs.nodes
    .filter(node => node.frontmatter.template === 'SingleArticle')
    .flatMap(node => node.frontmatter)
    .slice(0, 6)

  return (
    <Layout>
      <main>
        <Help guides={guides} docs={docs} />
        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default HelpPage

export const pageQuery = graphql`
  query {
    docs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/docs/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          featured
          template
          readTime
          date(formatString: "MMMM D, YYYY")
          topic
          author {
            name
            role
          }
        }
        fields {
          slug
        }
      }
    }
    guides: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/guides/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          featured
          template
          readTime
          date(formatString: "MMMM D, YYYY")
          topic
          author {
            name
            role
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
